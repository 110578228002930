import React from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import NavBar from './NavBar/NavBar';
import SideBar from './SideBar/SideBar';
import { Paper, withStyles } from '@material-ui/core';

import Error from './Error/Error';

const drawerWidth = 200;
const appBarHeight = 100;

const styles = theme => ({
    // general styles
    content: {
        flexGrow: 1,
        border: 'none',
        paddingTop: appBarHeight,
        paddingLeft: `calc(${drawerWidth}px + ${theme.spacing(2)}px)`,
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
});

const ProtectedRoute = (props) => {
    const location = useLocation();
    const { isAuthenticated, isExpert, classes } = props;
    return (
        isAuthenticated ? <Paper elevation={0} className={classes.content}>
            <NavBar />
            <SideBar />
            {
                isExpert
                    ? <Outlet location />
                    : <Error errorMessage='You are not authorized to view this page!' />
            }
        </Paper> : <Navigate
            to="/login"
            state={ location }
        />
    );
}

export default withStyles(styles)(ProtectedRoute);
import React, { useState, useEffect } from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { getUser } from './Services/AuthService';
import ProtectedRoute from './Components/Core/ProtectedRoute';
import Login from './Components/Core/Login/Login';
import CreatePassword from './Components/CreatePassword/CreatePassword';
import TokenFetchError from './Components/Core/TokenFetchError/TokenFetchError';
import Error from './Components/Core/Error/Error';
import CaseManager from './Components/CaseManager/CaseManager';
import CaseDashboard from './Components/CaseDashboard/CaseDashboard';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword';
import InvalidLink from './Components/InvalidLink/InvalidLink';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import UserProfile from './Components/UserProfile/UserProfile';
import PdfViewer from './Components/Shared/PdfViewer/PdfViewer';
import PlainProtectedRoute from './Components/Core/PlainProtectedRoute';

export const history = createBrowserHistory();

function AppRoutes(props) {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isFetchUserComplete, setIsFetchUserComplete] = useState(false);
	const [isExpert, setIsExpert] = useState(false);

	useEffect(() => {
		const fetchUser = async () => {
			const user = await getUser();

			if (user && user.access_token && !user.expired) {
				setIsAuthenticated(true);
				setIsExpert(user.tenant === 'exp');
			}

			setIsFetchUserComplete(true);
		};

		fetchUser();
	}, []);

	return (
		<BrowserRouter history={history}>
			{!isFetchUserComplete ? (
				<div>Please wait...</div>
			) : (
				<Routes>
					<Route path="/login" element={<Login isAuthenticated={isAuthenticated} />} />
					<Route path='/error/:error' element={<Error />} />
					<Route path='/error' element={<Error />} />
					<Route path='/invalidlink' element={<InvalidLink />} />
					<Route path='/tokenfetcherror' element={<TokenFetchError />} />
					<Route path='/forgotpassword' element={<ForgotPassword />} />
					<Route path='/createpassword' element={<CreatePassword />} />
					<Route path='/resetpassword' element={<ResetPassword />} />
					<Route exact path="/" element={<ProtectedRoute isExpert={isExpert} isAuthenticated={isAuthenticated} />} >
						<Route path="/" element={<CaseManager />} />
						<Route path="/cases/:id" element={<CaseDashboard />} />
						<Route path="/myprofile" element={<UserProfile />} />
					</Route>
					<Route path='/pdf-viewer' element={<PlainProtectedRoute isExpert={isExpert} isAuthenticated={isAuthenticated} />} >
						<Route path="/pdf-viewer" element={<PdfViewer />} />
					</Route>
				</Routes>
			)}
		</BrowserRouter>
	);
}

export default AppRoutes;
